import { Navigate, useRoutes } from "react-router-dom";
// config
import MyAuthGuard from "src/auth/MyAuthGuard";

// ---------------------------------------------------------

// ----------------------------------------------------------------------

//
import { PATH_AFTER_LOGIN } from "../config-global";
import {
  AdminListPage,
  BlueprintListPage,
  ChangePasswordPage,
  ComingSoonPage,
  GeneralAppPage,
  LoginPage,
  MaintenancePage,
  Page403,
  Page404,
  Page500,
  ProgramListPage,
  PublicAssessmentPage,
  PublicLearningPage,
  PublicLinkListPage,
  ReportDetailsPage,
  ReportListPage,
  ResetPasswordPage,
  StudentDetailsPage,
  StudentListPage,
  TeacherCreatePage,
  TeacherDetailsPage,
  TeacherListPage,
} from "./elements";
// auth
import GuestGuard from "../auth/GuestGuard";

import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";

// ?-----------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      ],
    },

    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },

        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "reset-password/:token", element: <ChangePasswordPage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: (
        <MyAuthGuard>
          <DashboardLayout />
        </MyAuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralAppPage /> },

        {
          path: "student",
          children: [
            {
              element: <Navigate to="/dashboard/student/list" replace />,
              index: true,
            },
            { path: "list", element: <StudentListPage /> },
            { path: ":id/:tab", element: <StudentDetailsPage /> },
            {
              path: ":id/assessments/:assessment_id/add-score",
              element: <StudentDetailsPage />,
            },
            {
              path: ":id/:tab/:assessment_id/add-score",
              element: <StudentDetailsPage />,
            },
          ],
        },
        {
          path: "teacher",
          children: [
            {
              element: <Navigate to="/dashboard/teacher/list" replace />,
              index: true,
            },
            { path: "list", element: <TeacherListPage /> },
            { path: ":id/:tab", element: <TeacherDetailsPage /> },
            { path: "new", element: <TeacherCreatePage /> },
          ],
        },
        {
          path: "course",
          children: [
            {
              element: <Navigate to="/dashboard/course/list" replace />,
              index: true,
            },
            { path: "list", element: <ProgramListPage /> },
          ],
        },
        {
          path: "blueprint",
          children: [
            {
              element: <Navigate to="/dashboard/blueprint/list" replace />,
              index: true,
            },
            { path: "list", element: <BlueprintListPage /> },
          ],
        },
        {
          path: "admin",
          children: [
            {
              element: <Navigate to="/dashboard/admin/list" replace />,
              index: true,
            },
            { path: "list", element: <AdminListPage /> },
          ],
        },
        {
          path: "public-link",
          children: [
            {
              element: <Navigate to="/dashboard/public-link/list" replace />,
              index: true,
            },
            { path: "list", element: <PublicLinkListPage /> },
          ],
        },

        // { path: "settings", element: <UserAccountPage /> },

        // {
        //   path: "user-management",
        //   children: [
        //     {
        //       element: (
        //         <Navigate to="/dashboard/user-management/user/list" replace />
        //       ),
        //       index: true,
        //     },

        //     {
        //       path: "user",
        //       children: [
        //         {
        //           element: (
        //             <Navigate
        //               to="/dashboard/user-management/user/list"
        //               replace
        //             />
        //           ),
        //           index: true,
        //         },
        //         {
        //           path: "pInviECom_Server/.envrofile",
        //           element: <UserProfilePage />,
        //         },
        //         { path: "list", element: <UserListPage /> },
        //         { path: "new", element: <UserCreatePage /> },
        //         { path: ":userId/edit", element: <UserCreatePage /> },
        //         { path: ":userId/view", element: <UserViewPage /> },
        //         { path: "profile", element: <UserProfilePage /> },
        //       ],
        //     },

        //     {
        //       path: "role",
        //       children: [
        //         {
        //           element: (
        //             <Navigate
        //               to="/dashboard/user-management/role/list"
        //               replace
        //             />
        //           ),
        //           index: true,
        //         },
        //         { path: "list", element: <RoleListPage /> },
        //         { path: "new", element: <RoleCreatePage /> },
        //         { path: ":roleId/edit", element: <RoleCreatePage /> },
        //         { path: ":roleId/view", element: <RoleViewPage /> },
        //       ],
        //     },
        //   ],
        // },

        {
          path: "report",
          children: [
            {
              element: <Navigate to="/dashboard/report/list" replace />,
              index: true,
            },
            { path: "list", element: <ReportListPage /> },
            { path: ":id", element: <ReportDetailsPage /> },
          ],
        },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoonPage /> },
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        {
          path: "student-learning-plan/:token",
          element: <PublicLearningPage />,
        },
        {
          path: "student-assessment-report/:token",
          element: <PublicAssessmentPage />,
        },
      ],
    },
    // { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
