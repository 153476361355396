// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
  },
  student: {
    root: path(ROOTS_DASHBOARD, "/student"),
    list: path(ROOTS_DASHBOARD, "/student"),
    new: path(ROOTS_DASHBOARD, "/student/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/student/${id}/details`),
    tab: (id, tab) => path(ROOTS_DASHBOARD, `/student/${id}/${tab}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/student/${id}/edit`),
  },
  teacher: {
    root: path(ROOTS_DASHBOARD, "/teacher"),
    list: path(ROOTS_DASHBOARD, "/teacher"),
    new: path(ROOTS_DASHBOARD, "/teacher/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/teacher/${id}/details`),
    tab: (id, tab) => path(ROOTS_DASHBOARD, `/teacher/${id}/${tab}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/teacher/${id}/edit`),
  },
  course: {
    root: path(ROOTS_DASHBOARD, "/course/list"),
    list: path(ROOTS_DASHBOARD, "/course/list"),
    new: path(ROOTS_DASHBOARD, "/course/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/course/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/course/${id}/edit`),
  },
  blueprint: {
    root: path(ROOTS_DASHBOARD, "/blueprint/list"),
    list: path(ROOTS_DASHBOARD, "/blueprint/list"),
    new: path(ROOTS_DASHBOARD, "/blueprint/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/blueprint/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/blueprint/${id}/edit`),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, "/admin"),
    list: path(ROOTS_DASHBOARD, "/admin"),
    new: path(ROOTS_DASHBOARD, "/admin/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/admin/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/admin/${id}/edit`),
  },

  publicLink: {
    root: path(ROOTS_DASHBOARD, "/public-link"),
    list: path(ROOTS_DASHBOARD, "/public-link"),
  },

  // userManagement: {
  //   root: path(ROOTS_DASHBOARD, "/user-management/"),
  //   user: {
  //     root: path(ROOTS_DASHBOARD, "/user-management/user/"),
  //     list: path(ROOTS_DASHBOARD, "/user-management/user/list"),
  //     new: path(ROOTS_DASHBOARD, "/user-management/user/new"),
  //     profile: path(ROOTS_DASHBOARD, "/user-management/user/profile"),
  //     edit: (name) =>
  //       path(ROOTS_DASHBOARD, `/user-management/user/${name}/edit`),
  //     view: (name) =>
  //       path(ROOTS_DASHBOARD, `/user-management/user/${name}/view`),
  //   },

  //   role: {
  //     root: path(ROOTS_DASHBOARD, "/user-management/role/"),
  //     list: path(ROOTS_DASHBOARD, "/user-management/role/list"),
  //     new: path(ROOTS_DASHBOARD, "/user-management/role/new"),
  //     profile: path(ROOTS_DASHBOARD, "/user-management/role/profile"),
  //     edit: (name) =>
  //       path(ROOTS_DASHBOARD, `/user-management/role/${name}/edit`),
  //     view: (name) =>
  //       path(ROOTS_DASHBOARD, `/user-management/role/${name}/view`),
  //   },
  // },
};
